/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { types, prop, tProp, Model, Ref } from 'mobx-keystone';

/**
 * Typescript enum
 */

export enum NotificationLevels {
  Alarm = 'Alarm',
  Info = 'Info',
  Off = 'Off',
  Warning = 'Warning',
}

/**
 * NotificationLevels
 *
 * Notification Levels
 */
export const NotificationLevelsEnumType = types.enum(NotificationLevels);
