import { Button, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { observer } from 'mobx-react-lite';
import type Form from 'mobx-react-form';
import { useQuery } from 'react-query';
import { appStore, useAppStore, userModelPrimitives } from '~/store';
import {
  CustomMobxForm,
  isAlphanumeric,
  isLength,
  isRequired,
  isValidLatitude,
  isValidLongitude,
  plugins,
} from '~/components/form';
import { Modal } from '~/components/Modal';

import './styles.css';
import { useDashboardTranslation } from '~/translations/use-dashboard-translation';
import { TextMaskCustom } from '~/components/InputMask';

const fields = {
  name: {
    name: 'name',
    label: 'Name (required)',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],
    bindings: 'MaterialTextField',
  },
  latitude: {
    name: 'latitude',
    label: 'Latitude (required)',
    validators: [isValidLatitude, isRequired],
    bindings: 'MaterialTextField',
  },
  longitude: {
    name: 'longitude',
    label: 'Longitude (required)',
    validators: [isValidLongitude, isRequired],
    bindings: 'MaterialTextField',
  },
  fullAddress: {
    name: 'fullAddress',
    label: 'Address (required)',
    validators: [isAlphanumeric, isRequired, isLength({ min: 1 })],
    bindings: 'MaterialTextField',
  },
  siteManager: {
    name: 'siteManager',
    label: 'Site Manager',
    validators: [isAlphanumeric, isRequired],
    bindings: 'MaterialSelectField',
  },
};

const hooks = {
  onSuccess(form: Form) {
    // get field values
    const values = form.values();
    appStore.graphql
      .mutateCreateSite({
        site: { ...values, latitude: parseFloat(values.latitude), longitude: parseFloat(values.longitude) },
      })
      .then(() => {
        appStore.modal.hideModal();
        form.reset();
      });
  },
  onError(form: Form) {
    // get all form errors
    window.console.log('All form errors', form.errors());
  },
};
const myForm = new CustomMobxForm({ fields }, { plugins, hooks });

export const AddSite = observer(() => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();

  useQuery('users', () => store.graphql.queryUsers({}, userModelPrimitives.sites((site) => site.id).toString()));
  return (
    <Modal onClose={() => store.modal.hideModal()}>
      <div className="admin-add-modal">
        <h3>
          {t('add')} {t('site')}
        </h3>
        <form autoComplete="off" className="single-form" onSubmit={(e) => myForm.onSubmit(e as unknown as Event)}>
          <Grid container justify="center" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...myForm.$('name').bind()}
                label={myForm
                  .$('name')
                  .label.split(' ')
                  .map((l) => t(l))
                  .join(' ')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...myForm.$('latitude').bind()}
                label={myForm
                  .$('latitude')
                  .label.split(' ')
                  .map((l) => t(l))
                  .join(' ')}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  inputProps: { type: 'latitude' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...myForm.$('longitude').bind()}
                label={myForm
                  .$('longitude')
                  .label.split(' ')
                  .map((l) => t(l))
                  .join(' ')}
                name="numberformat"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  inputProps: { type: 'longitude' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...myForm.$('fullAddress').bind()}
                label={myForm
                  .$('fullAddress')
                  .label.split(' ')
                  .map((l) => t(l))
                  .join(' ')}
              />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <FormControl>
                <InputLabel shrink htmlFor="site-simple">
                  {t('site-manager')} {t('(required)')}
                </InputLabel>
                <Select
                  {...myForm.$('siteManager').bind()}
                  label={myForm
                    .$('siteManager')
                    .label.split(' ')
                    .map((l) => t(l))
                    .join(' ')}
                >
                  {Array.from(store.graphql.users.values(), (opt) => (
                    <MenuItem key={opt.id} value={opt.id} children={`${opt.firstName} ${opt.lastName}`} />
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <Button
                type="submit"
                children={t('submit')}
                variant="contained"
                onClick={() => {
                  myForm.submit();
                }}
                disabled={!myForm.isValid}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
});
