import { FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { CustomMobxForm, isAlphanumeric, isLength, isNumeric, isRequired, plugins } from '~/components/form';

import { Modal } from '~/components/Modal';

import './styles.css';
import { appStore, useAppStore } from '~/store';
import { useDashboardTranslation } from '~/translations/use-dashboard-translation';

const fields = {
  serialNumber: {
    name: 'serialNumber',
    label: 'Serial Number (required)',
    placeholder: 'Serial Number (required)',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],
    bindings: 'MaterialTextField',
  },
  site: {
    name: 'site',
    label: 'Site',
    placeholder: 'Site',
    validators: [isNumeric, isRequired],
    bindings: 'MaterialSelectField',
  },
  vaultType: {
    name: 'vaultType',
    label: 'Vault Type (required)',
    placeholder: 'Vault Type',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],

    bindings: 'MaterialSelectField',
  },
  telitThingId: {
    name: 'telitThingId',
    label: 'Telit Thing ID (required)',
    placeholder: 'Telit Thing ID',
    validators: [isAlphanumeric, isLength({ min: 1, max: 46 })],
    bindings: 'MaterialTextField',
  },
  firmwareVersion: {
    name: 'firmwareVersion',
    label: 'Firmware Version',
    placeholder: 'Firmware Version',
    validators: [isAlphanumeric, isLength({ min: 0, max: 46 })],
    bindings: 'MaterialTextField',
  },
};

const hooks = {
  onSuccess: (form) => {
    // get field values
    appStore.graphql.mutateCreateVault({ vault: form.values() }).then(() => {
      appStore.modal.hideModal();
      form.reset();
    });
  },
  onError(form) {
    // get all form errors
    window.console.log('All form errors', form.errors());
  },
};
const myForm = new CustomMobxForm({ fields }, { plugins, hooks });

export const AddVault = observer(() => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  useQuery('sites', () => store.graphql.querySites());

  return (
    <Modal isOpen onClose={store.modal.hideModal}>
      <div className="admin-add-modal">
        <h3>{`${t('add')} ${t('vault')}`}</h3>
        <form autoComplete="off" className="single-form" onSubmit={(e) => myForm.onSubmit(e as unknown as Event)}>
          <Grid container justify="center" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <TextField {...myForm.$('serialNumber').bind()} label={t(myForm.$('serialNumber').label)} />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <FormControl>
                <InputLabel shrink htmlFor="site-simple">
                  {`${t('site')} ${t('(required)')}`}
                </InputLabel>
                <Select {...myForm.$('site').bind()}>
                  {Array.from(store.graphql.sites.values(), (opt) => (
                    <MenuItem key={opt.id} value={opt.id} children={opt.name} />
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="grid-item">
              <FormControl>
                <InputLabel shrink htmlFor="vaultType-simple">
                  {`${t('vault')} ${t('type')} ${t('(required)')}`}
                </InputLabel>
                <Select {...myForm.$('vaultType').bind()}>
                  {['ISC', 'ISR'].map((opt) => (
                    <MenuItem key={opt} value={opt} children={opt} />
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField {...myForm.$('telitThingId').bind()} label={t(myForm.$('telitThingId').label)} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...myForm.$('firmwareVersion').bind()} label={t(myForm.$('firmwareVersion').label)} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  myForm.submit();
                }}
                disabled={!myForm.isValid}
                type="submit"
              >
                {`${t('submit')}`}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
});
