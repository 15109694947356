import { ExtendedModel, model } from 'mobx-keystone';
import { DeviceModelBase } from './DeviceModel.base';

/* A graphql query fragment builders for DeviceModel */
export { selectFromDevice, deviceModelPrimitives, DeviceModelSelector } from './DeviceModel.base';

/**
 * DeviceModel
 */
@model('Device')
export class DeviceModel extends ExtendedModel(DeviceModelBase, {}) {}
