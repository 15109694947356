import Button from '@material-ui/core/Button';
import { PureComponent } from 'react';

import { Modal } from '~/components/Modal';

import './styles.css';

interface Props {
  callback: any;
  text: string;
  cancelText: string;
  confirmText: string;
}
export class Confirm extends PureComponent<Props> {
  confirmClicked = () => {
    this.props.callback(true);
    // this.props.hideModal();
  };

  cancelClicked = () => {
    this.props.callback(false);
    // this.props.hideModal();
  };

  render() {
    const { text, cancelText, confirmText } = this.props;

    const buttonStyle = {
      marginLeft: 7,
    };

    const contentStyle = {
      width: '437px',
    };

    const footer = (
      <div className="modal-footer">
        {cancelText && <Button children={cancelText} onClick={this.cancelClicked} />}
        <Button children={confirmText} onClick={this.confirmClicked} variant="contained" style={buttonStyle} />
      </div>
    );

    return (
      <Modal isOpen onClose={() => {}} contentStyle={contentStyle} shouldCloseOnOverlayClick>
        <div className="confirm-modal-content">
          <div className="confirm-modal-text">{text}</div>
          {footer}
        </div>
      </Modal>
    );
  }
}
