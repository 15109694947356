import { Route, Switch, useHistory } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import { Loading } from '~/components/Loader';

function NotFound() {
  return <h1>404.. Page not found!</h1>;
}

export enum MapViews {
  Cameras = 'cameras',
  Vehicles = 'vehicles',
}

export enum AdminViews {
  Users = 'users',
  Sites = 'sites',
  Devices = 'devices',
  Vaults = 'vaults',
  VaultMessages = 'vault-messages',
  DeviceMessages = 'device-messages',
  RequestList = 'request-list',
}

export enum Views {
  Map = 'map',
  Devices = 'devices',
  Notifications = 'notifications',
  Admin = 'admin',
}

export function formatPath(key: string, paths?: Array<string>) {
  return `/:${key}${paths ? `(${paths.join('|')})` : ''}`;
}

export const Debug = lazy(() => import('./views/admin/pages/Debug'));
export const Device = lazy(() => import('./views/admin/pages/Device'));
export const Sites = lazy(() => import('./views/admin/pages/Sites'));
export const VaultDebug = lazy(() => import('./views/admin/pages/VaultDebug'));
export const Vaults = lazy(() => import('./views/admin/pages/Vaults'));
export const Users = lazy(() => import('./views/admin/pages/User/index'));

/**
 * Creates a full path with a match param key and path keys (e.g. [{view: ['map']}] turns into /:view(map))
 * @param paths
 */
export function stringifyPath(paths: Record<string, Array<string>>): string {
  return Object.entries(paths)
    .map(([key, value]) => formatPath(key, value))
    .join('');
}

export function Router() {
  const history = useHistory();
  useEffect(() => {
    if (history.location?.pathname === '/' || history.location?.pathname === '/map') {
      history.push(`/map/cameras`);
    }
  }, [history.location?.pathname]);
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={stringifyPath({
            view: [Views.Map],
            viewType: [MapViews.Cameras, MapViews.Vehicles],
          })}
          component={lazy(() => import('./views/map/index'))}
        />
        <Route
          path={stringifyPath({
            view: [Views.Admin],
            viewType: Object.values(AdminViews),
          })}
          component={lazy(() => import('./views/admin/index'))}
        />
        <Route
          path={stringifyPath({
            view: [Views.Devices],
          })}
          component={lazy(() => import('./views/devices/index'))}
        />
        <Route
          path={stringifyPath({
            view: [Views.Notifications],
          })}
          component={lazy(() => import('./views/notifications/index'))}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}
