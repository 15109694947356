import type { PropsWithChildren } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import english from './en/dashbord.json';
import russian from './ru/dashbord.json';

interface ProviderProps extends PropsWithChildren<{}> {}

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      dashboard: english,
    },
    ru: {
      dashboard: russian,
    },
  },
});

export function TranslationProvider(props: ProviderProps) {
  return <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>;
}
