import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { PureComponent } from 'react';
import { ErrorPage } from './ErrorPage';

type ErrorProps = RouteComponentProps<any> & any;
// eslint-disable-next-line @typescript-eslint/naming-convention
class _ErrorBoundary extends PureComponent<ErrorProps, { hasError: boolean; error: any; errorInfo: any }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  componentDidUpdate(prevProps: Readonly<ErrorProps>): void {
    if (this.state.hasError && prevProps.location.pathname !== this.props.location.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    window.console.log(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(_ErrorBoundary);
