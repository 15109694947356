import { makeAutoObservable, observable } from 'mobx';

interface Filters {
  alarmed: boolean;
  docked: boolean;
  inuse: boolean;
}

export class MapState {
  position?: any = null;
  detailData: any = observable.object({});
  detailType?: any | string | null = null;
  detailVisible: boolean = false;
  filters: Filters = {
    alarmed: false,
    docked: false,
    inuse: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  closeDetail() {
    this.detailData = {};
    this.detailType = null;
    this.detailVisible = false;
  }

  openDetail(type, data) {
    this.detailData = data;
    this.detailType = type;
    this.detailVisible = true;
  }

  updateDetailType(type, data) {
    this.detailData = data;
    this.detailType = type;
  }

  toggleFilter(filter) {
    this.filters[filter] = !this.filters[filter];
  }
}
