import type { ThemeOptions } from '@material-ui/core';
import { createTheme } from '@material-ui/core';

const fontFamily = 'Roboto, sans-serif';
const themeOptions = {
  darkMode: true,
  palette: {
    type: 'dark',
    primary: {
      main: '#2d3139',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FFFFFF',
    },
    background: {
      default: '#272D36',
      secondary: '#464b55',
    },
  },
  typography: {
    fontFamily,
  },
  overrides: {
    MuiListItem: {
      root: {
        fontFamily,
        color: 'white',
        textDecoration: 'none',
      },
    },
    MuiMenuItem: {
      root: { fontFamily },
    },
    MuiList: {
      root: {
        // color: 'black',
      },
    },
    MuiSelect: {
      icon: {
        color: '#2d3139',
      },
    },
  },
};

type IRTheme = typeof themeOptions;
declare module '@material-ui/core/styles' {
  export interface Theme extends IRTheme, ThemeOptions {}
  // eslint-disable-next-line @typescript-eslint/no-shadow
  export interface ThemeOptions extends IRTheme {}
}

export const theme = createTheme(themeOptions as IRTheme & ThemeOptions);
