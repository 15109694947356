/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import type { IObservableArray } from 'mobx';
import { types, prop, tProp, Model, Ref, idProp } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';
import type { CamerasPresentModel } from './CamerasPresentModel';
import type { LoginHistoryModel } from './LoginHistoryModel';
import type { SiteModel } from './SiteModel';
import type { StatsModel } from './StatsModel';
import type { UserModel } from './UserModel';
import type { VaultEventModel } from './VaultEventModel';

import { CamerasPresentModelSelector, camerasPresentModelPrimitives } from './CamerasPresentModel';
import { LoginHistoryModelSelector, loginHistoryModelPrimitives } from './LoginHistoryModel';
import { SiteModelSelector, siteModelPrimitives } from './SiteModel';
import { StatsModelSelector, statsModelPrimitives } from './StatsModel';
import { UserModelSelector, userModelPrimitives } from './UserModel';
import { VaultEventModelSelector, vaultEventModelPrimitives } from './VaultEventModel';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  camerasPresent: IObservableArray<CamerasPresentModel>;
  events: IObservableArray<VaultEventModel>;
  lastLogin: UserModel;
  loginHistory: IObservableArray<LoginHistoryModel>;
  site: SiteModel;
  stats: IObservableArray<StatsModel>;
};

/**
 * VaultBase
 * auto generated base class for the model VaultModel.
 */
export class VaultModelBase extends Model({
  __typename: tProp('Vault'),
  batteryLevel: prop<number | null>().withSetter(),
  camerasPresent: prop<Ref<CamerasPresentModel>[]>(() => []).withSetter(),
  events: prop<Ref<VaultEventModel>[] | null>(() => []).withSetter(),
  firmwareVersion: prop<string | null>().withSetter(),
  id: prop<number>().withSetter(),
  lastLogin: prop<Ref<UserModel> | null>().withSetter(),
  lastModified: prop<any | null>().withSetter(),
  loginHistory: prop<Ref<LoginHistoryModel>[]>(() => []).withSetter(),
  nextAlert: prop<any | null>().withSetter(),
  nextAlertHours: prop<number | null>().withSetter(),
  powerLevel: prop<number | null>().withSetter(),
  serialNumber: prop<string | null>().withSetter(),
  site: prop<Ref<SiteModel>>().withSetter(),
  stats: prop<(Ref<StatsModel> | null)[] | null>(() => []).withSetter(),
  status: prop<string | null>().withSetter(),
  telitThingId: prop<string | null>().withSetter(),
  temperature: prop<number | null>().withSetter(),
  vaultType: prop<string | null>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class VaultModelSelector extends QueryBuilder {
  get batteryLevel() {
    return this.__attr(`batteryLevel`);
  }
  get firmwareVersion() {
    return this.__attr(`firmwareVersion`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get lastModified() {
    return this.__attr(`lastModified`);
  }
  get nextAlert() {
    return this.__attr(`nextAlert`);
  }
  get nextAlertHours() {
    return this.__attr(`nextAlertHours`);
  }
  get powerLevel() {
    return this.__attr(`powerLevel`);
  }
  get serialNumber() {
    return this.__attr(`serialNumber`);
  }
  get status() {
    return this.__attr(`status`);
  }
  get telitThingId() {
    return this.__attr(`telitThingId`);
  }
  get temperature() {
    return this.__attr(`temperature`);
  }
  get vaultType() {
    return this.__attr(`vaultType`);
  }
  camerasPresent(
    builder?:
      | string
      | CamerasPresentModelSelector
      | ((selector: CamerasPresentModelSelector) => CamerasPresentModelSelector)
  ) {
    return this.__child(`camerasPresent`, CamerasPresentModelSelector, builder);
  }
  events(
    builder?: string | VaultEventModelSelector | ((selector: VaultEventModelSelector) => VaultEventModelSelector)
  ) {
    return this.__child(`events`, VaultEventModelSelector, builder);
  }
  lastLogin(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) {
    return this.__child(`lastLogin`, UserModelSelector, builder);
  }
  loginHistory(
    builder?: string | LoginHistoryModelSelector | ((selector: LoginHistoryModelSelector) => LoginHistoryModelSelector)
  ) {
    return this.__child(`loginHistory`, LoginHistoryModelSelector, builder);
  }
  site(builder?: string | SiteModelSelector | ((selector: SiteModelSelector) => SiteModelSelector)) {
    return this.__child(`site`, SiteModelSelector, builder);
  }
  stats(builder?: string | StatsModelSelector | ((selector: StatsModelSelector) => StatsModelSelector)) {
    return this.__child(`stats`, StatsModelSelector, builder);
  }
}
export function selectFromVault() {
  return new VaultModelSelector();
}

export const vaultModelPrimitives =
  selectFromVault().batteryLevel.firmwareVersion.lastModified.nextAlert.nextAlertHours.powerLevel.serialNumber.status
    .telitThingId.temperature.vaultType;
