import { makeAutoObservable, runInAction } from 'mobx';
import { isValidHttpUrl } from '~/components/util';
import type { NotificationLevels } from '~/store/graphql';
import { RoleLevels } from '~/store/graphql';
import type { AppStore } from '~/store/root';

export class AuthState {
  rootStore: AppStore;
  username = '';
  isAdmin = false;
  role: RoleLevels = RoleLevels.Basic;
  dashboardNotifications: NotificationLevels;
  notifications: NotificationLevels;
  get user() {
    return {
      username: this.username,
      isAdmin: this.isAdmin,
      role: this.role,
    };
  }

  get isDebug() {
    return this.role === RoleLevels.Debug;
  }

  async checkLogin() {
    const handleUnAuth = async (resp: Response) => {
      const dat = await resp.text();
      if (dat) {
        const redirectUrl = dat.replace('Unauthorized. Redirecting to ', '');
        if (isValidHttpUrl(redirectUrl)) window.location = redirectUrl as unknown as Location;
      } else {
        throw Error('Unauthorized');
      }
    };
    let resp: Response;
    try {
      resp = await fetch(`${import.meta.env.DEV ? 'http://localhost:7979' : window.location.origin}/api/check_login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          get Authorization() {
            const search = new URLSearchParams(window.location.search);
            return `Bearer ${search.get('code')}`;
          },
        },
      });
      if (resp.status !== 200) {
        await handleUnAuth(resp);
      } else {
        try {
          const data = await resp.json();
          runInAction(() => {
            this.username = data.username;
            this.isAdmin = data.isAdmin;
            this.role = data.role;
            this.dashboardNotifications = data.dashboardNotifications;
            this.notifications = data.notifications;
          });
        } catch {
          return Error('Unauthorized');
        }
      }
    } catch (e) {
      await handleUnAuth(resp);
    }
  }
  async logOut() {
    const logout = await this.rootStore.graphql.mutateSignOut();
    if (logout) {
      if (isValidHttpUrl(logout.signOut)) window.location = logout.signOut as unknown as Location;
    }
    this.checkLogin();
  }
  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
}
