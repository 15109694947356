import type { ThemeOptions } from '@material-ui/core';
import { Dialog, DialogContent, ThemeProvider, createTheme } from '@material-ui/core';
import type { MouseEvent, MouseEventHandler, PropsWithChildren } from 'react';
import { useCallback } from 'react';

import './styles.css';

interface ModalProps extends PropsWithChildren<{}> {
  isOpen?: boolean;
  onClose?: MouseEventHandler<HTMLElement>;
  shouldCloseOnOverlayClick?: boolean;
}

const themeOptions: ThemeOptions = {
  darkMode: true,
  palette: {
    type: 'light',
  },
};
const theme = createTheme(themeOptions);

export function Modal({ isOpen = true, onClose, shouldCloseOnOverlayClick = true, children }: ModalProps) {
  const handleOverlayClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (shouldCloseOnOverlayClick && onClose) {
        onClose(e);
      }
    },
    [shouldCloseOnOverlayClick, onClose]
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={handleOverlayClick} className="modal-container">
        <DialogContent className="modal-content">{children}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
