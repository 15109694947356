import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import type { AppStore } from './root';

export const AppStoreContext = createContext<null | AppStore>(null);

export function useAppStore(): AppStore {
  const store = useContext(AppStoreContext);
  if (store === null) {
    throw new Error('~/store cannot be null, please add a context provider');
  }
  return store;
}

export const AppStoreProvider = observer((props: PropsWithChildren<{ store: AppStore }>) => (
  <AppStoreContext.Provider value={props.store}>{props.children}</AppStoreContext.Provider>
));
