import { ExtendedModel, model } from 'mobx-keystone';
import { VaultModelBase } from './VaultModel.base';

/* A graphql query fragment builders for VaultModel */
export { selectFromVault, vaultModelPrimitives, VaultModelSelector } from './VaultModel.base';

/**
 * VaultModel
 */
@model('Vault')
export class VaultModel extends ExtendedModel(VaultModelBase, {}) {}
