import { observer } from 'mobx-react-lite';
import { useAppStore } from '~/store';
import { AddDevice as AddDeviceModal } from './AddDevice';
import { AddSite as AddSiteModal } from './AddSite';

import { AddUser as AddUserModal } from './AddUser';
import { AddVault as AddVaultModal } from './AddVault';
import { Confirm as ConfirmModal } from './Confirm';

export enum ModalTypes {
  AddUser,
  AddDevice,
  AddSite,
  AddVault,
  Confirm,
}
const MODAL_COMPONENTS = {
  [ModalTypes.AddUser]: AddUserModal,
  [ModalTypes.AddDevice]: AddDeviceModal,
  [ModalTypes.AddSite]: AddSiteModal,
  [ModalTypes.AddVault]: AddVaultModal,
  [ModalTypes.Confirm]: ConfirmModal,
};

export const Modal = observer(() => {
  const store = useAppStore();
  if (store.modal.modalType === undefined || store.modal.modalType === null) {
    return null;
  }

  // @ts-ignore
  const SpecificModal = MODAL_COMPONENTS[store.modal.modalType as any] as any;
  return <SpecificModal {...store.modal.modalProps} />;
});
