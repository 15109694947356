import { createHttpClient } from 'mk-gql';
import { Model, model, prop, registerRootStore } from 'mobx-keystone';
import { configure } from 'mobx';
import { QueryClient } from 'react-query';
import { isValidHttpUrl } from '~/components/util';
import { RootStore } from './graphql';
import { MapState } from './map';
import { AuthState } from './auth';
import { ModalState } from './modal';
import { Admin } from './admin';

configure({
  enforceActions: 'never',
});
@model('AppStore')
export class AppStore extends Model({ graphql: prop<RootStore>(() => new RootStore({})) }) {
  map: MapState;
  modal: ModalState;
  auth: AuthState;
  admin: Admin;
  queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  protected onAttachedToRootStore() {
    this.map = new MapState();
    this.auth = new AuthState(this);
    this.modal = new ModalState();
    this.admin = new Admin();
    this.graphql.middleWare = (resp) => {
      resp.catch((e) => {
        try {
          const error = JSON.parse(e.message.replace('GraphQL Error (Code: 401): ', ''));
          if (error.response) {
            const redirectUrl = error.response.error.replace('Unauthorized. Redirecting to ', '');
            if (isValidHttpUrl(redirectUrl)) window.location = redirectUrl;
          }
        } catch (error) {
          this.auth.checkLogin();
        }
      });
    };
    this.graphql.gqlHttpClient = createHttpClient(
      `${import.meta.env.DEV ? 'http://localhost:7979' : window.location.origin}/api/graphql`,
      {
        credentials: 'include',
        redirect: 'follow',
        headers: {
          credentials: 'include',
          get Authorization() {
            const search = new URLSearchParams(window.location.search);
            return `Bearer ${search.get('code')}`;
          },
        },
      }
    );
  }
}

export const appStore = new AppStore({});
registerRootStore(appStore);
