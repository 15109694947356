import { makeAutoObservable } from 'mobx';
import type { ModalTypes } from '~/views/modal/RootModal';

export class ModalState {
  modalType: null | ModalTypes = null;
  modalProps: object = {};
  constructor() {
    makeAutoObservable(this);
  }
  hideModal = () => {
    this.modalType = null;
    this.modalProps = {};
  };

  showModal(type: ModalTypes, props?: any) {
    this.modalType = type;
    this.modalProps = props;
  }

  updateModal(props: object) {
    this.modalProps = props;
  }
}
