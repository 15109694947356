import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import { Person, PhotoCamera, Place, Settings, Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { NotificationLevels, useAppStore } from '~/store';
import { useDashboardTranslation } from '~/translations/use-dashboard-translation';
import { AlertBell } from '../notifications/AlertBell';
import './styles.css';

const views = [
  { view: 'map', Icon: Place, link: [`/map/cameras`, '/map/vehicles'] },
  { view: 'devices', Icon: PhotoCamera, link: '/devices' },
  { view: 'notifications', Icon: Warning, link: '/notifications' },
];

const LinkButton = Button;

const useStyles = makeStyles((theme: any) => ({
  navBar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: '72px',
    width: '100vw',
    flex: '0 0 72px',
    fontFamily: theme.typography.fontFamily,
  },
}));

export const DashboardNav = observer(() => {
  const store = useAppStore();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useDashboardTranslation();

  return (
    <nav className={classes.navBar}>
      <div className="nav-buttons">
        <div className="left">
          <AlertBell />
        </div>
        {views.map(({ Icon, link, view }) => {
          const disabled = view === 'notifications' && store.auth.dashboardNotifications === NotificationLevels.Off;
          if (disabled) return null;
          return (
            <LinkButton
              component={Link as any}
              key={view}
              css={css`
                &:hover {
                  cursor: ${disabled ? 'not-allowed' : 'pointer'};
                  background: ${disabled ? 'transparent !important' : '#fff'};
                }
              `}
              className={`nav-button${
                (
                  Array.isArray(link)
                    ? link.some((l) => location.pathname?.startsWith(l))
                    : location.pathname?.startsWith(link)
                )
                  ? ' active'
                  : ''
              }`}
              activeClassName="active"
              cy-test={view}
              onClick={() => !disabled && history.push(Array.isArray(link) ? link[0] : link)}
            >
              <Icon style={{ marginRight: '0.5rem', height: '5rem', width: '2rem' }} />
              {t(view as any)}
            </LinkButton>
          );
        })}
        <div className="nav-button right">
          <Button cy-test="user" color="secondary">
            <Person />
            {store.auth.username}
          </Button>
          <Button cy-test="logout" color="secondary" onClick={() => store.auth.logOut()}>
            {t('logout')}
          </Button>
          {store.auth.isAdmin && (
            <LinkButton
              cy-test="settings"
              component={Link as any}
              className={`admin-button${location.pathname.startsWith('/admin') ? ' active' : ''}`}
              activeClassName="active"
              to="/admin/users"
            >
              <Settings />
            </LinkButton>
          )}
        </div>
      </div>
    </nav>
  );
});
