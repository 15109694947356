import type Form from 'mobx-react-form';
import type { Field } from 'mobx-react-form';
import MobxReactForm from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';
import validator from 'validator';

export const plugins = {
  vjf: vjf(validator),
};

export function isRequired({ field }: { field: Field; form: Form }) {
  return [
    (field.value !== undefined && field.value !== null) || !!String(field.value).replace(/\s/g, '').length,
    `The ${field.label} is required.`,
  ];
}

export function isOptionalList({ validators }: { validators: any[] }) {
  return (items: { field: Field; form: Form }) => {
    if (!items.field.value) return [true, 'Valid'];
    return validators.map((vld) => vld(items));
  };
}

export function isLength({ min, max }: { min: number; max?: number }) {
  return ({ field }: { field: Field; form: Form }) => [
    validator.isLength(field.value, { min, max }),
    `Must be ${min}-${max} in length`,
  ];
}

export function isAlpha({ field }: { field: Field; form: Form }) {
  return [typeof field.value === 'string', `This field must be only letters`];
}

export function isValidLongitude({ field }: { field: Field; form: Form }) {
  return [field.value >= -180 && field.value <= 180, `This field must be only letters`];
}

export function isValidLatitude({ field }: { field: Field; form: Form }) {
  return [field.value >= -90 && field.value <= 90, `This field must be only letters`];
}

function hasWhiteSpace(s: string) {
  const whitespaceChars = [' ', '\t', '\n'];
  return whitespaceChars.some((char) => s.includes(char));
}

export function hasNoWhiteSpace({ field }: { field: Field; form: Form }) {
  return [!hasWhiteSpace(field.value), `This field must not contain spaces`];
}

export function isAlphanumeric({ field }: { field: Field; form: Form }) {
  return [typeof field.value === 'string', `This field must be letters and numbers`];
}

export function isBoolean({ field }: { field: Field; form: Form }) {
  return [
    field.value === true ||
      field.value === false ||
      field.value === 0 ||
      field.value === 1 ||
      field.value === '0' ||
      field.value === '1' ||
      field.value === 'true' ||
      field.value === 'false',
    `This field must be true or false`,
  ];
}

export function isNumeric({ field }: { field: Field; form: Form }) {
  return [validator.isNumeric(String(field.value)), `This field must numbers`];
}

export function shouldBeEqualTo(target: string) {
  return ({ field, form }: { field: Field; form: Form }) => {
    const fieldsAreEquals = form.$(target).value === field.value;
    return [fieldsAreEquals, `The ${field.label} should be equal to ${form.$(target).label}`];
  };
}

export function isEmail({ field }: { field: Field; form: Form }) {
  const isValid = field.value.indexOf('@') > 0;
  return [isValid, `The ${field.label} should be an email address.`];
}

export class CustomForm extends MobxReactForm {
  bindings(): any {
    return {
      MaterialSelectField: {
        id: 'id',
        name: 'name',
        value: 'value',
        label: 'label',
        disabled: 'disabled',
        error: 'error',
        onChange: 'onChange',
        onBlur: 'onBlur',
        onFocus: 'onFocus',
        autoFocus: 'autoFocus',
        defaultValue: 'default',
      },
      MaterialTextField: ({ $try, field, props }: any) => ({
        type: $try(props.type, field.type),
        id: $try(props.id, field.id),
        name: $try(props.name, field.name),
        value: $try(props.value, field.value),
        floatingLabelText: $try(props.label, field.label),
        hintText: $try(props.placeholder, field.placeholder),
        errorText: field.validating ? props.validatingText : $try(props.error, field.error),
        disabled: $try(props.disabled, field.disabled),
        onChange: $try(props.onChange, field.onChange),
        onBlur: $try(props.onBlur, field.onBlur),
        onFocus: $try(props.onFocus, field.onFocus),
        autoFocus: $try(props.autoFocus, field.autoFocus),
        label: props.hasError ? $try(props.errorText, field.errorText) : $try(props.label, field.label),
        placeholder: $try(props.helperText, field.helperText),
        error: $try(props.error, field.error),
        defaultValue: $try(props.default, field.default),
      }),
    };
  }
}
export const CustomMobxForm = CustomForm;
