import type { PropsWithChildren } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { observer, useLocalObservable } from 'mobx-react-lite';
import moment from 'moment';
import { useDashboardTranslation } from '~/translations/use-dashboard-translation';
import type { AlertModel } from '~/store';
import { AlertCriticality, useAppStore } from '~/store';

interface AlertCardProps extends PropsWithChildren<{}> {
  data: AlertModel;
  refetch: () => any;
}

const alertDefaultStyle = {
  padding: '5px 5px !important',
  color: 'white',
  backgroundColor: '#272d36',
};
const style = {
  [AlertCriticality.Alarm]: {
    borderLeft: '7px solid #FC474B',
    ...alertDefaultStyle,
  },
  [AlertCriticality.Warning]: {
    borderLeft: '7px solid #F1C40F',
    ...alertDefaultStyle,
  },
  [AlertCriticality.Info]: {
    borderLeft: '7px solid #3FC851',
    ...alertDefaultStyle,
  },
};

export const AlertCard = observer<AlertCardProps>((AlertCardProps) => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const state = useLocalObservable(() => ({
    openConfirm: false,
    handleConfirmOpen() {
      this.openConfirm = true;
    },
    handleConfirmClose() {
      this.openConfirm = false;
    },
    async handleUpdateCheck() {
      const user = Array.from(store.graphql.users.values()).find((u) => u.username === store.auth.username);
      if (user) {
        await store.graphql.mutateSetHandledBy({
          alertId: AlertCardProps.data.id,
          userId: user.id,
        });
        AlertCardProps.refetch();
      }
      this.openConfirm = false;
    },
  }));

  return (
    <div style={{ marginBottom: '3px' }}>
      <Card style={AlertCardProps.data.criticality ? style[AlertCardProps.data.criticality] : {}}>
        <CardHeader
          style={{ paddingBottom: '5px', display: 'flex', alignItems: 'center' }}
          title={
            <div
              style={{
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <WarningIcon style={{ fontSize: '16px' }} />
              <span style={{ paddingLeft: '3px' }}>{AlertCardProps.data.device?.current?.serialNumber}</span>
            </div>
          }
          action={
            <div
              style={{
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="caption">{AlertCardProps.data.handledBy?.current?.username}</Typography>
              <IconButton size="small" disabled={!!AlertCardProps.data.isHandled} onClick={state.handleConfirmOpen}>
                <CheckCircleOutlineIcon
                  style={{
                    color: AlertCardProps.data.isHandled ? 'green' : 'lightgrey',
                  }}
                />
              </IconButton>
            </div>
          }
        />
        <CardContent
          style={{
            color: 'black',
          }}
        >
          <Grid container spacing={2}>
            <Grid container item alignItems="center" justify="center" xs={6}>
              <Card elevation={0} style={{ width: '100%' }}>
                <CardHeader
                  title={<Typography variant="caption">{t('description')}</Typography>}
                  subheader={
                    <Typography variant="body1">
                      {t(AlertCardProps.data.type || (AlertCardProps.data.criticality as any))}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid container item alignItems="center" justify="center" xs={6}>
              <Card elevation={0} style={{ width: '100%' }}>
                <CardHeader
                  title={<Typography variant="caption">{t('timestamp')}</Typography>}
                  subheader={
                    <Typography variant="body1">
                      {moment.utc(AlertCardProps.data.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
          </Grid>
          <Dialog open={state.openConfirm} onClose={state.handleConfirmClose} fullWidth maxWidth="sm">
            <DialogContent>
              <Typography variant="h6">{t('set-notification-as-complete')}?</Typography>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: 'white' }} onClick={state.handleConfirmClose} color="inherit">
                {t('Cancel')}
              </Button>
              <Button style={{ color: 'white' }} onClick={state.handleUpdateCheck} color="inherit">
                {t('Complete')}
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
});
