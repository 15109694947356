/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from 'mobx-keystone';
import { QueryBuilder } from 'mk-gql';
import type { DeviceModel } from './DeviceModel';
import type { VaultModel } from './VaultModel';

import { DeviceModelSelector, deviceModelPrimitives } from './DeviceModel';
import { VaultModelSelector, vaultModelPrimitives } from './VaultModel';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  device: DeviceModel;
  vault: VaultModel;
};

/**
 * CamerasPresentBase
 * auto generated base class for the model CamerasPresentModel.
 */
export class CamerasPresentModelBase extends Model({
  __typename: tProp('CamerasPresent'),
  device: prop<Ref<DeviceModel>>().withSetter(),
  id: prop<number>().withSetter(),
  isPresent: prop<boolean | null>().withSetter(),
  vault: prop<Ref<VaultModel>>().withSetter(),
}) {
  getRefId() {
    return String(this.id);
  }
}

export class CamerasPresentModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`);
  }
  get isPresent() {
    return this.__attr(`isPresent`);
  }
  device(builder?: string | DeviceModelSelector | ((selector: DeviceModelSelector) => DeviceModelSelector)) {
    return this.__child(`device`, DeviceModelSelector, builder);
  }
  vault(builder?: string | VaultModelSelector | ((selector: VaultModelSelector) => VaultModelSelector)) {
    return this.__child(`vault`, VaultModelSelector, builder);
  }
}
export function selectFromCamerasPresent() {
  return new CamerasPresentModelSelector();
}

export const camerasPresentModelPrimitives = selectFromCamerasPresent().isPresent;
