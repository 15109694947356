import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import type { SiteModel } from '~/store';
import { siteModelPrimitives, useAppStore } from '~/store';
import { useDashboardTranslation } from '~/translations/use-dashboard-translation';

interface SiteListProps {
  checked: Array<SiteModel>;
  setChecked: (checked: Array<SiteModel> | undefined) => void;
}

export const SiteList = observer((props: SiteListProps) => {
  const store = useAppStore();
  const { t } = useDashboardTranslation();
  const { data } = useQuery('sites', async () => await store.graphql.querySites({}, siteModelPrimitives.toString()));
  const handleToggle = (value) => {
    const currentIndex = props.checked.findIndex((val) => Number(val.id) === Number(value.id));
    const checked = props.checked.slice();
    if (currentIndex === -1) {
      checked.push(value);
    } else {
      checked.splice(currentIndex, 1);
    }
    props.setChecked(checked);
  };

  return (
    <>
      <Typography variant="subtitle1">{`${t('sites')}`}</Typography>
      <List style={{ maxHeight: '175px', overflowY: 'scroll' }}>
        {data?.sites?.map((value: SiteModel) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={value.id}
              role={undefined}
              dense
              button
              style={{ maxHeight: '35px' }}
              onClick={() => handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={!!props.checked?.find?.((item) => Number(item.id) === Number(value.id))}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
});
